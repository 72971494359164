<template>
    <div class="min-h-full flex flex-col justify-center">
        <div class="sm:mx-auto sm:w-full sm:max-w-md">
            <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
                Sign Up
            </h2>
            <div class="mt-6 text-center">
                <router-link
                    to="/login"
                    class="w-full text-center text-primary-600 text-sm hover:text-primary-400"
                >
                    Already have an account? Login here.
                </router-link>
            </div>
        </div>

        <div class="mt-8 mb-8 flex justify-center">
            <div class="bg-white py-8 shadow rounded-lg px-10">
                <div
                    class=""
                    role="alert"
                    v-if="error !== null"
                >
                    {{ error }}
                </div>
                <SignUpFields />
            </div>
        </div>
    </div>
</template>

<script>
    // <!-- API -->
    import { defineComponent, ref } from 'vue';

    // <!-- COMPONENTS -->
    import SignUpFields from '~Auth/components/SignUpFields.vue';

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'SignUp',
        components: {
            SignUpFields,
        },
        setup() {
            return {
                error: ref(null),
            };
        },
    });
</script>
